@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  background-color: $main !important;
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 300;
}

.top-bar-web__menu {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: $main;

  .top-bar-web__menu__logo {
    img {
      width: 110px;
    }
  }

  .top-bar-web__user {
    display: flex;
    align-items: center;
    cursor: pointer;

    .top-bar-web__user__name {
      color: $textSecondary;
      margin-left: 10px;
      font-weight: 400;
    }
  }
}

.top-bar-web__menu__navigation {
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  .top-bar-web__menu__navigation__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      font-weight: bold;
      margin-left: 5px;
      font-size: 18px;
    }
  }
}

.section-wrap {
  padding: 20px 0;
  margin-bottom: 20px;
}

.section-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text;
  h2 {
    font-weight: bold;
    font-size: 43px;
  }
  h2,
  h5 {
    margin: 0;
    padding: 0;
  }

  .section-title__actions {
    color: $secondary;
    font-weight: bold;
    cursor: pointer;
  }
}

.product-row {
  width: 32%;
  margin-bottom: 20px;
  border-radius: 20px;
  .product-row__image {
    width: 100%;
    height: 300px;
    background-size: cover !important;
    background-position: center !important;
  }
  .product-row__info {
    width: 100%;
    height: 150px;
    background-color: darken($main, 3);
    padding: 20px;
    color: $text;

    .product-row__info__name {
      font-weight: bold;
      cursor: pointer;
    }
    .product-row__info__price {
      display: flex;
      align-items: center;
      .product-row__info__price__regular {
        margin-right: 10px;
        color: $textSecondary;
        text-decoration: line-through;
      }
      .product-row__info__price__sale {
        font-weight: bold;
        font-size: 19px;
      }
    }
  }

  .product-row__actions {
    margin-top: 10px;
  }
}

.grid-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dark-section {
  background-color: darken($main, 4);
}

.categories-banner {
  .categories-banner__vehicle-image {
    img {
      width: 100%;
    }
  }
  .categories-banner__list {
    margin-top: 40px;
    ul {
      color: $text;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li {
        display: block;
        width: 50%;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}

.post-row {
  height: 200px;
  width: 24%;
  display: flex;
  align-items: flex-end;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 20px;
  padding: 10px;

  .post-row__info-wrap {
    display: flex;
    align-items: center;

    .post-row__info-wrap__avatar {
      flex-shrink: 0;
    }

    .post-row-wrap__info {
      color: $text;
      margin-left: 10px;

      .post-row-wrap__info__user {
        font-weight: bold;
        line-height: 0.75;
      }
      span {
        display: block;
      }
    }
  }
}

.vehicle-selected {
  .vehicle-selected__image {
    width: 100%;
  }
  .vehicle-selected__info__brand {
    color: $textSecondary;
    font-size: 19px;
    line-height: 0.75;
  }
  .vehicle-selected__info__change {
    color: $secondary;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    cursor: pointer;
  }
}

.product__info {
  color: $text;
  .product__info__caption {
    color: $textSecondary;
    font-size: 14px;
    display: block;
  }

  .product__info__name {
    span {
      font-size: 26px;
      font-weight: bold;
    }
  }
  .product__info__price {
    .product__info__price__number {
      color: $text;
      font-size: 28px;
      font-weight: bold;
      display: block;
    }
  }

  .product__info__category {
    .product__info__category__text {
      font-weight: bold;
      color: $secondary;
    }
  }

  .product__info__addToCart {
    margin-top: 20px;
  }
}

.tab__content {
  background-color: darken($main, 4);
  padding: 20px;
  color: $text;
}

.vehicle {
  width: 24%;
  margin-bottom: 20px;
  img {
    width: 100%;
  }

  .vehicle__info {
    background-color: darken($main, 3);
    padding: 10px;
    color: $text;
  }
  .vehicle__info__name {
    font-weight: bold;
    font-size: 18px;
  }
  .vehicle__info__brand {
    color: $textSecondary;
  }
  .vehicle__img {
    background-color: darken($main, 2);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
  }
}

.login__login {
  background-color: darken($main, 3);
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 30px;

  h4 {
    color: $text;
  }

  label {
    color: $text;
  }
}

.login__register__action {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    color: $text;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.text {
  color: $text;
}

.left-sidebar__menu {
  width: 200px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f7f7fa;
  z-index: 900;

  h2 {
    color: $white;
    padding-left: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 20px;
  }

  nav {
    padding-top: 10px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        width: 100%;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 10px;

        span {
          color: $white;
          font-weight: bold;
          font-size: 19px;
        }
      }
    }
  }
}

.admin__content__wrap {
  padding-left: 200px;

  .admin__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bar-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  .bar-actions__left {
    flex: 1;
    display: flex;
    align-items: center;

    .bar-actions__left__search {
      flex: 1;
      margin-right: 20px;
    }
  }

  .bar-actions__right {
    margin-left: 20px;
  }
}

.search-bar {
  display: flex;

  .search-bar__input {
    flex: 1;
  }
  .search-bar__icon {
    margin-right: 10px;
    padding-top: 5px;
  }
}

.input__item {
  margin-top: 20px;
  span {
    font-weight: bold;
    display: block;

    margin-bottom: 3px;
  }
}

.input-error {
  border-color: red !important;
}
.main-layout {
  padding-bottom: 100px;
}

.galeria-producto {
  .galeria-producto__portada {
    img {
      width: 100%;
    }
  }

  .galeria-producto__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;
    .galeria-producto__item {
      width: 23%;
      height: 100px;
      background-size: cover !important;
      background-position: center !important;
    }
  }
}

.table__image {
  width: 50px;
  height: 50px;
  background-size: cover !important;
  background-position: center !important;
}

.rs-tag-text {
  font-weight: bold !important;
}

.rs-btn-subtle,
.rs-nav-item {
  font-weight: bold !important;
}

.rs-message-body {
  a {
    font-weight: bold !important;
    color: #2196f3 !important;
  }
}

.td--has-img {
  img {
    width: 100px;
  }
}

.form__item {
  margin-bottom: 20px;
}

// =============================== TEMPLATE ADMIN LECXA ==========================
.bg--dark {
  background-color: darken($bg, 90);
}

.main {
  background-color: $bg;
  min-height: 100vh;
}

.main-content {
  margin-top: 20px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.main-top {
  background-color: $accent !important;
  padding-top: 10px;
  .main-top__navigation {
    background-color: $accent !important;
  }
  .main-top__bottom {
    height: 55px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    .main-top__bottom__section {
      h1 {
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        padding: 0;
      }
    }

    .main-top__bottom__section {
    }
  }
}

.rs-breadcrumb {
  margin-bottom: 0 !important;

  .rs-breadcrumb-item {
    font-size: 16px;
  }
}

.producto-pos {
  padding-right: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .producto-pos__info {
    display: flex;
    align-items: center;
  }

  .producto-pos__precio {
    display: flex;
    align-items: center;

    .producto-pos__precio-regular-tachado {
      margin-right: 10px;
      text-decoration: line-through;
      font-size: 16px;
    }

    .producto-pos__precio-oferta {
      margin-right: 10px;
      font-weight: bold;
    }

    .producto-pos__precio-regular {
      margin-right: 10px;
      font-weight: bold;
    }
  }
}

.producto-pos:hover {
  color: blue;
}

.pos__productos {
  height: 600px;
  overflow-y: scroll;
}

.pos__total {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  height: 600px;
  overflow-y: scroll;
  background-color: #f2f2f2;

  .pos__total__cliente {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pos__total__total {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: bold;
    }
  }

  .pos__total__pagar {
    height: 50px;
  }

  .bold {
    font-weight: bold;
  }
  .pos__total__items {
    background-color: #e4e4e4;
    min-height: 250px;
    max-height: 250px;
    overflow-y: scroll;
    padding: 5px;

    .pos__total__items__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      border-bottom: 1px solid rgb(226, 226, 226);
      padding-bottom: 5px;

      .pos__total__items__item__price {
        display: flex;
        align-items: center;
        h6 {
          margin-right: 10px;
        }
      }
    }

    .pos__total__items__item:hover {
      color: red;
    }
  }
}

.venta {
  .venta__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .venta__totals {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .venta__totals__item {
      display: flex;
      align-items: center;

      .venta__totals__item__text {
        margin-left: 10px;
        font-weight: bold;
        font-size: 21px;
      }

      .venta__totals__item__grand-total {
        font-size: 36px;
      }
    }
  }
}

.dashboard {
  width: 100%;

  .dashboard__saludo {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .dashboard__saludo__nombre {
      h1 {
        line-height: 0.75;
        font-weight: bold;
      }
    }
  }
}

.lista-box-pedido {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  h6 {
    font-weight: bold;
  }
}

.rs-btn {
  font-weight: bold !important;
}
.mensajes {
  max-height: 500px;
  overflow-y: scroll;
}
.mensaje {
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

  .mensaje__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      padding: 0;
      line-height: 1;
    }
    span {
      margin-left: 10px;
      font-size: 11px;
      padding-top: 2px;
      font-weight: 500;
      color: $textSecondary;
    }
  }

  .mensaje__texto {
    font-weight: 500;
    color: $textSecondary;
  }
}

.pos__total__cupon {
  margin-bottom: 10px;
}

.dashboard-box {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 20px;
}

.dashboard__totales {
  .dashboard__totales__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dashboard__totales__top__item {
      width: 50%;
    }
  }

  .dashboard__totales__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    .dashboard__totales__info__item {
      width: 33.3333%;
      text-align: center;
    }
  }
}

.fade:not(.show) {
  opacity: 1 !important;
}

.rs-picker-menu,
.fade,
.in,
.rs-picker-select-menu,
.fade {
  opacity: 1 !important;
}

.in {
  opacity: 1 !important;
}
